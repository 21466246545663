var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" },
        [
          _c(
            "c-table",
            {
              ref: "table",
              attrs: {
                title: "공지사항 목록",
                tableId: "table",
                columnSetting: false,
                isFullScreen: false,
                columns: _vm.grid.columns,
                data: _vm.grid.data,
                isExcelDown: false,
              },
              on: { rowClick: _vm.rowClick },
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _c("c-btn", {
                        attrs: { label: "검색", icon: "search" },
                        on: { btnClicked: _vm.getNoticeList },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7" },
        [
          _c(
            "q-form",
            { ref: "editForm" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "상세" },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-8" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable && _vm.dataeditable,
                            required: true,
                            label: "공지사항 제목",
                            name: "noticeTitle",
                          },
                          model: {
                            value: _vm.data.noticeTitle,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "noticeTitle", $$v)
                            },
                            expression: "data.noticeTitle",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-select", {
                          attrs: {
                            editable: _vm.editable && _vm.dataeditable,
                            codeGroupCd: "NOTICE_CLASS_CD",
                            type: "edit",
                            required: true,
                            itemText: "codeName",
                            itemValue: "code",
                            name: "grade",
                            label: "공지사항분류",
                          },
                          model: {
                            value: _vm.data.noticeClassCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "noticeClassCd", $$v)
                            },
                            expression: "data.noticeClassCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable && _vm.dataeditable,
                            type: "editor",
                            label: "내용",
                            name: "noticeContents",
                            editheight: 30,
                          },
                          model: {
                            value: _vm.data.noticeContents,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "noticeContents", $$v)
                            },
                            expression: "data.noticeContents",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  staticStyle: { "margin-top": "10px" },
                  attrs: { title: "첨부파일" },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col-12",
                        staticStyle: { "margin-top": "10px" },
                      },
                      [
                        _c("c-upload", {
                          attrs: {
                            attachInfo: _vm.attachInfo,
                            editable: false,
                            label: "첨부파일",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }